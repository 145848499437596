'use client';

import { createElement, type FunctionComponent, type PropsWithChildren, type ReactHTML } from 'react';

import { extendStyles, type StyleExtensions } from '~modules/ui';
import { useSetCssVariable } from '~modules/ui/hooks';

import classes from './StackedButton.module.css';

interface StackedButtonProps extends PropsWithChildren {
    RenderComponent?: FunctionComponent<any> | keyof ReactHTML | React.ElementType;
    baseColor?: string;
    extend?: StyleExtensions<keyof typeof classes>;

    [key: string]: any;
}

export const StackedButton = ({
    RenderComponent = 'button',
    baseColor,
    extend,
    children,
    ...props
}: StackedButtonProps) => {
    const { ref } = useSetCssVariable({
        variables: {
            '--baseColor': baseColor,
        },
    });

    const allClasses = extendStyles(classes, extend);

    return createElement(
        RenderComponent,
        {
            ...props,
            className: allClasses.container,
            ref,
        },
        <span className={allClasses.innerWrapper}>{children}</span>,
    );
};
