import { createElement, type PropsWithChildren } from 'react';

import { extendStyles } from '~modules/ui';
import type { StyleExtensions } from '~modules/ui/types';

import classes from './Paragraph.module.css';

export interface ParagraphProps extends PropsWithChildren {
    extend?: StyleExtensions<keyof typeof classes>;
    html?: string;
}

export const Paragraph = ({ html, extend, children }: ParagraphProps) => {
    const allClasses = extendStyles(classes, extend);

    return createElement(`p`, {
        className: allClasses.container,
        ...(html && {
            dangerouslySetInnerHTML: {
                __html: html,
            },
        }),
        ...(!html && {
            children,
        }),
    });
};
