import { combineClassNames } from '~modules/ui';

type Classes = Record<string, string>;

export function extendStyles<T extends Classes, U extends Classes = Classes>(
    classes: T,
    ...extensions: ReadonlyArray<Partial<U> | undefined>
) {
    const allKeys: ReadonlyArray<keyof T | keyof U> = [
        ...new Set([...Object.keys(classes), ...extensions.flatMap(v => Object.keys(v ?? {}))]),
    ];

    return Object.fromEntries(
        allKeys.map(key => {
            // @ts-expect-error keyof T|U used to index T | U
            const allClasses = combineClassNames(classes[key], ...extensions.map(e => e?.[key]));

            return [key, allClasses];
        }),
    ) as T & U;
}
