import { useEffect, useRef } from 'react';

export interface UseSetCssVariableProps {
    variables: Record<string, number | string | undefined>;
}

export function useSetCssVariable<T extends HTMLElement>({ variables }: UseSetCssVariableProps) {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        if (!ref.current) return;
        for (const key in variables) {
            const value = variables[key];

            if (value === undefined) continue;

            ref.current.style.setProperty(key, String(value));
        }
    }, [ref, variables]);

    return { ref };
}
