'use client';

import { useEffect, useRef, type ComponentProps, type FC } from 'react';

import { combineClassNames, extendStyles, Paragraph, type StyleExtensions } from '~modules/ui';

import ArrowLeftIcon from '../ArrowLeftIcon';
import flipButtonClasses from './FlipButton.module.css';
import CardLayout from './Layout';
import teamCardClasses from './TeamCard.module.css';

export interface TeamBackCardProps {
    name: string;
    surname: string;
    link: string | null;
    motto: string;
    handleFlip: Function;

    extend?: StyleExtensions<keyof typeof teamCardClasses | keyof typeof flipButtonClasses> &
        ComponentProps<typeof CardLayout>['extend'];
}

export const TeamBackCard: FC<TeamBackCardProps> = ({ name, surname, link, motto, handleFlip, extend }) => {
    const ref = useRef<HTMLDivElement>(null);
    const allClasses = extendStyles(teamCardClasses, flipButtonClasses, extend);

    // FIXME: Ugly hack to force Safari to re-render element to calculate correct height
    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = `100.0001%`;

            const timeoutId = setTimeout(() => {
                ref.current && (ref.current.style.height = `100%`);
            }, 100);

            return () => clearTimeout(timeoutId);
        }
    }, [extend]);

    return (
        <CardLayout name={name} surname={surname} link={link} extend={extend}>
            <div className={allClasses.backBody} ref={ref}>
                <Paragraph extend={{ container: allClasses.paragraphExtend }} html={motto} />

                <div className={allClasses.bodyMottoRow}>
                    <span>&nbsp;</span>
                    <button
                        type='button'
                        className={combineClassNames(allClasses.flipButton, allClasses.back)}
                        onClick={() => handleFlip()}
                    >
                        <ArrowLeftIcon />
                    </button>
                </div>
            </div>
        </CardLayout>
    );
};
