import type { FC } from 'react';

export const ArrowRightIcon: FC = props => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='15' height='26' viewBox='0 0 15 26' {...props}>
            <polyline
                fill='none'
                stroke='#00F'
                strokeWidth='2'
                points='48 19 36 31 24 19'
                transform='rotate(-90 15.5 33.5)'
            />
        </svg>
    );
};

export default ArrowRightIcon;
