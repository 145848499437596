import { useState, type FC } from 'react';

import { TeamBackCard, TeamFrontCard } from '../TeamCard';
import type { TeamBackCardProps } from '../TeamCard/TeamBackCard';
import type { TeamFrontCardProps } from '../TeamCard/TeamFrontCard';
import classes from './TeamFlippableCard.module.css';

export const TeamFlippableCard: FC<Omit<TeamFrontCardProps & TeamBackCardProps, 'handleFlip'>> = ({
    name,
    surname,
    link,
    sinceYear,
    specialization,
    motto,
    mottoTitle,
    ImageComponent,
    videoSrc,
}) => {
    const [flipped, setFlipped] = useState<boolean>(false);
    const handleClick = () => setFlipped(wasFlipped => !wasFlipped);

    return (
        <div className={classes.wrapper} data-flipped={flipped ? '' : undefined}>
            <TeamFrontCard
                name={name}
                surname={surname}
                link={link}
                sinceYear={sinceYear}
                specialization={specialization}
                handleFlip={handleClick}
                extend={{ wrapper: classes.frontWrapper }}
                ImageComponent={ImageComponent}
                videoSrc={videoSrc}
                mottoTitle={mottoTitle}
            />

            <TeamBackCard
                name={name}
                surname={surname}
                link={link}
                motto={motto}
                handleFlip={handleClick}
                extend={{ wrapper: classes.backWrapper, body: classes.backBody }}
            />
        </div>
    );
};
