import type { ComponentProps, FC, ReactNode } from 'react';

import { combineClassNames, extendStyles, type StyleExtensions } from '~modules/ui';

import AckeeIcon from '../AckeeIcon';
import ArrowRightIcon from '../ArrowRightIcon';
import flipButtonClasses from './FlipButton.module.css';
import CardLayout from './Layout';
import classes from './TeamCard.module.css';

export interface TeamFrontCardProps {
    name: string;
    surname: string;
    link: string | null;
    sinceYear: string | number;
    specialization: string;
    handleFlip?: Function;
    extend?: StyleExtensions<keyof typeof classes> & ComponentProps<typeof CardLayout>['extend'];
    ImageComponent?: ReactNode;
    videoSrc?: any;
    mottoTitle: string;
}

export const TeamFrontCard: FC<TeamFrontCardProps> = ({
    name,
    surname,
    link,
    sinceYear,
    mottoTitle,
    specialization,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleFlip,
    extend,
    ImageComponent,
    videoSrc,
}) => {
    const allClasses = extendStyles(classes, extend);

    return (
        <CardLayout name={name} surname={surname} link={link} extend={extend}>
            <div className={allClasses.picture}>
                <div className={allClasses.pictureCircle}>
                    {videoSrc && (
                        <video autoPlay muted loop className={allClasses.video}>
                            <source src={videoSrc} type='video/mp4' />
                            {ImageComponent ? ImageComponent : `ERR`}
                        </video>
                    )}
                    {!videoSrc && ImageComponent}
                </div>
            </div>

            <div className={allClasses.bodyRow}>
                <AckeeIcon />
                &nbsp;since&nbsp;{sinceYear}
            </div>
            <div className={allClasses.bodyRow}>{specialization}</div>
            <div className={allClasses.bodyMottoRow}>
                <span>{mottoTitle}</span>
                {handleFlip && (
                    <button
                        type='button'
                        className={combineClassNames(flipButtonClasses.flipButton)}
                        onClick={() => handleFlip()}
                    >
                        <ArrowRightIcon />
                    </button>
                )}
            </div>
        </CardLayout>
    );
};
