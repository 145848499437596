export const ArrowIcon = ({ fill = `#000`, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' {...props}>
            <g fill='none' fillRule='evenodd' stroke={fill} strokeWidth='3' transform='matrix(-1 0 0 1 25 1)'>
                <polyline points='0 24 0 0 24 0' />
                <line x2='24' y2='24' />
            </g>
        </svg>
    );
};

export default ArrowIcon;
