import { useEffect, useState } from 'react';

// Caching
let _isSafari: boolean | undefined = undefined;

export function useIsSafari() {
    const [isSafari, setIsSafari] = useState<boolean>(Boolean(_isSafari));

    useEffect(() => {
        if (_isSafari !== undefined) {
            setIsSafari(_isSafari);

            return;
        }

        _isSafari =
            // @ts-ignore
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === `[object SafariRemoteNotification]`;
            })(
                // @ts-ignore
                !window[`safari`] ||
                    // @ts-ignore
                    (typeof safari !== `undefined` && safari.pushNotification),
            );

        setIsSafari(_isSafari);
    }, []);

    return isSafari;
}
