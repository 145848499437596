import type { ComponentProps, PropsWithChildren } from 'react';
import Link from 'next/link';

import { extendStyles } from '~modules/ui';
import { useSetCssVariable } from '~modules/ui/hooks';

import classes from './SimpleLink.module.css';

export interface SimpleLinkProps extends PropsWithChildren<ComponentProps<typeof Link>> {
    opacity?: number;
}

export const SimpleLink = ({ className, opacity, ...props }: SimpleLinkProps) => {
    const { ref } = useSetCssVariable<HTMLAnchorElement>({
        variables: {
            '--SimpleLink-opacity': opacity,
        },
    });

    const allClasses = extendStyles(classes, { link: className });

    return <Link className={allClasses.link} {...props} ref={ref} />;
};
