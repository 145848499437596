'use client';

import type { PropsWithChildren } from 'react';

import { extendStyles, type StyleExtensions } from '~modules/ui';
import { useIsSafari } from '~modules/ui/hooks';

import ArrowIcon from '../../ArrowIcon';
import { SimpleLink } from '../../SimpleLink';
import classes from './Layout.module.css';

export interface CardLayoutProps extends PropsWithChildren {
    name: string;
    surname: string;
    link: string | null;
    extend?: StyleExtensions<keyof typeof classes>;
}

const CardLayout = ({ name, surname, link, children, extend }: CardLayoutProps) => {
    const isSafari = useIsSafari();
    const allClasses = extendStyles(classes, extend);

    return (
        <div className={allClasses.wrapper}>
            <div className={allClasses.wrapperBg} />
            <div className={allClasses.content}>
                <div className={allClasses.header}>
                    <h3 className={allClasses.headerLeft}>
                        <span>{name}</span>
                        <span>{surname}</span>
                    </h3>
                    {link && (
                        <div className={allClasses.headerRight}>
                            <SimpleLink
                                href={link}
                                target='_blank'
                                rel='noopener noreferrer'
                                opacity={isSafari ? 1 : 0.6}
                            >
                                <ArrowIcon />
                            </SimpleLink>
                        </div>
                    )}
                </div>

                <div className={allClasses.body}>{children}</div>
            </div>
        </div>
    );
};

export default CardLayout;
